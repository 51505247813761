
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { PopoverBase } from '@gsk-tech/gsk-popover/gsk-popover-base';
import { Events } from '@/constants';
import { openErrorSnackbar, openSuccessSnackbar, randId } from '@/utils/components';

@Component({})
export default class GPopover extends Vue {
  @Prop(Boolean) open!: boolean;
  @Prop(Boolean) noPad!: boolean;
  @Prop({ type: HTMLElement, required: false }) anchor?: HTMLElement;

  // the following properties only work if hover is used instead of "open" property
  @Prop(Boolean) hover!: boolean;
  @Prop(String) anchorId?: string;
  @Prop(Boolean) pinnable!: boolean;
  @Prop({ type: Number, default: 800 }) pinDelay!: number;
  @Prop(Boolean) copy!: boolean;

  $refs!: {
    popover?: PopoverBase;
  };

  internalOpen = false;
  instanceId = randId();
  pinned = false;
  pinTimeoutId = -1;

  get isOpen(): boolean {
    if (this.hover) {
      return this.internalOpen;
    }
    return this.open;
  }

  anchorCache: HTMLElement | null = null;
  anchorElement(): HTMLElement | null {
    let anchor: HTMLElement | null = null;
    if (this.anchor) {
      anchor = this.anchor;
    } else if (this.anchorCache) {
      return this.anchorCache;
    } else if (this.anchorId) {
      anchor = document.getElementById(this.anchorId);
      if (anchor) {
        this.anchorCache = anchor;
      }
    }
    return anchor;
  }

  ignoreUpdate = false;
  created() {
    this.$root.$on(Events.OpenPopover, (opts: { id: string }) => {
      if (opts.id !== this.instanceId && this.internalOpen) {
        // a terrible hack
        this.ignoreUpdate = true;
        this.internalOpen = false;
        this.pinned = false;
      }
    });
  }

  async mounted() {
    if (this.hover) {
      let i = 0;
      while (!this.anchorElement()) {
        await this.$nextTick();
        if (i === 10) {
          // just in case, should be like 1 or 0 ticks;
          break;
        }
      }
      this.anchorElement()?.addEventListener('mouseleave', () => {
        if (!this.pinned) {
          this.internalOpen = false;
          clearTimeout(this.pinTimeoutId);
        }
        this.pinTimeoutId = -1;
      });
      this.anchorElement()?.addEventListener('mouseenter', () => {
        if (this.pinnable) {
          this.pinTimeoutId = setTimeout(() => {
            this.pinned = true;
          }, this.pinDelay);
        }
        if (this.internalOpen) {
          this.doUpdate(true);
        } else {
          this.internalOpen = true;
        }
      });
    }
  }

  doUpdate(open: boolean) {
    if (this.anchorElement()) {
      this.$root.$emit(open ? Events.OpenPopover : Events.ClosePopover, {
        anchor: this.anchorElement(),
        noPad: this.noPad,
        id: this.instanceId,
      });
      if (!open) {
        this.pinned = false;
      }
    }
  }

  @Watch('isOpen', { immediate: true })
  updatePopover(open: boolean, prev?: boolean): void {
    if (!open && prev === undefined) {
      return;
    }
    if (this.ignoreUpdate) {
      this.ignoreUpdate = false;
      return;
    }
    this.doUpdate(open);
  }

  clickAway(e: PointerEvent) {
    // avoid closing the popover if the click path contains the anchor element
    const isAnchorClick = e.composedPath().indexOf(this.anchorElement() as EventTarget) !== -1;
    if (this.pinnable && !isAnchorClick) {
      this.internalOpen = false;
    }
  }

  copyToClipboard() {
    const strings = this.$slots.default?.map(vnode => vnode.elm?.textContent ?? '') ?? [];
    const text = strings.join('\n');
    navigator.clipboard
      .writeText(text)
      .then(() => {
        openSuccessSnackbar.call(this, 'Copied to clipboard');
        this.internalOpen = false;
      })
      .catch(err => openErrorSnackbar.call(this, err));
  }
}
