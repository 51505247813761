import { AxiosPromise } from 'axios';
import axios from '@/api/service';
import URLs from '@/api/service/urls';
import {ServiceProjectMigrationRegistrationDto}  from '@/types/service-project-movement.types';

export function getMigrateServieList(params: {page: number, limit: number, q: string} ): AxiosPromise<ServiceProjectMigrationRegistrationDto> {
  return axios.get(URLs.ServiceProjectMovementMigrateServiceList, {params});
}

export function setRegistrationProvisioned(serviceId: number, sourceProjectId: number, destinationProjectId: number): AxiosPromise<boolean> {
  return axios.put(URLs.ServiceProjectMovementOnboardingToOtherProject, {
    serviceId,
    sourceProjectId,
    destinationProjectId
  });
}
