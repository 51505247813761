
import { Vue, Prop, Component } from 'vue-property-decorator';
import IconMenu from '@/components/gsk-components/menu/IconMenu.vue';
import { MenuOption } from '@/components/gsk-components/menu/menu.types';

@Component({
  components: {
    IconMenu,
  },
})
export default class TablePagination extends Vue {
  @Prop({ type: Number, required: true }) page!: number;
  @Prop({ type: Number, required: true }) results!: number;
  @Prop({ type: Number, required: true }) totalresults!: number;
  @Prop({ type: Boolean, required: true }) hideback!: boolean;
  @Prop({ type: Boolean, required: true }) hideforward!: boolean;

  public options: MenuOption[] = [
    { key: '10', text: '10' },
    { key: '25', text: '25' },
    { key: '50', text: '50' },
    { key: '75', text: '75' },
    { key: '100', text: '100' },
  ];

  public get range() {
    if (this.totalresults === 0) {
      return '0-0';
    }
    let end = String(this.page * this.results);
    if (this.page * this.results > this.totalresults) {
      end = String(this.totalresults);
    }
    const range = String(1 + (this.page - 1) * this.results) + '-' + end;
    return range;
  }

  setResults(option: MenuOption) {
    const value = Number(option.text);
    this.$emit('update:results', value);
  }

  back() {
    this.$emit('back');
  }

  forward() {
    this.$emit('forward');
  }
}
