
  import { Component, Vue } from 'vue-property-decorator';
  import { Promised } from 'vue-promised';
  import GButton from '@/components/gsk-components/GskButton.vue';
  import GDialog from '@/components/gsk-components/GskDialog.vue';
  import MaxWidth from '@/components/MaxWidth.vue';
  import DeleteDialog from '@/components/dialogs/DeleteDialog.vue';
  import ValidatedFormDialog from '@/components/dialogs/ValidatedFormDialog.vue';
  import { openErrorSnackbar, openSnackbar, sanitize } from '@/utils/components';
  import { getMigrateServieList, setRegistrationProvisioned } from '@/api/service-project-movement';
  import Grid from '@/components/grid/Grid';
  import GridCell from '@/components/grid/GridCell';
  import GPopover from '@/components/gsk-components/popover/GskPopover.vue';
  import CopyCode from '@/components/CopyCode.vue';
  import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import { ServiceProjectMigrationRegistrationDto } from '@/types/service-project-movement.types';
import TablePagination from '@/components/TablePagination.vue';
  
  @Component({
    components: {
      GButton,
      MaxWidth,
      Promised,
      ValidatedFormDialog,
      DeleteDialog,
      GDialog,
      Grid,
      GridCell,
      GPopover,
      CopyCode,
      GTextfield,
      TablePagination
    },
  })
  export default class ServiceProjectMovementView extends Vue {
    dataLoader: Promise<ServiceProjectMigrationRegistrationDto> | null = null;
    dialogOpen = false;
    serviceIdToSetProvisioned: string | null = null;
    public sanitizeLink = (source:string) => sanitize(source);
    searchTerm = '';
    totalRegistrations = 0;
    public page = 1;
    public rowsPerPage = 25;
     
    moveServiceClicked(regData: any, index: number): void {
      const destinationProjectId  = regData.selectedDestProject;
      const sourceProjectId = regData.projectId;
      const serviceId = regData.serviceId;
      setRegistrationProvisioned(serviceId, sourceProjectId, destinationProjectId).then(() => {
        openSnackbar.call(this, 'Service migrated to other projects successfully.');
        this.load();
      }).catch(err => {
        openErrorSnackbar.call(this, err?.response?.data?.error ?? 'Server Error');
      })
      return;
    }
  
    load() {
      this.dataLoader = getMigrateServieList({page: this.page - 1, limit: this.rowsPerPage, q: this.searchTerm}).
      then(r => r.data).
      then(data => {
        this.totalRegistrations = data.totalServiceCount;
        data.services.forEach((o) => {
          Vue.set(o, 'selectedDestProject', undefined)
          return o;
        });
        return data;
      });
    }
  
    fmtDate(d: string) {
      return new Date(d).toLocaleString();
    }
    created(): void {
      this.load();
    }

    onSearch(term:string) {
      this.searchTerm = term;
      this.load()
    }

    filterData(data: ServiceProjectMigrationRegistrationDto) {
      return data.services;
      /*if(this.searchTerm === ''){
        this.totalRegistrations = data.length;
        
      }
      const result = data.filter(reg => {
        return (reg.serviceProperties?.kong_service_name as unknown as string).indexOf(this.searchTerm) !== -1
      })
      this.totalRegistrations = result.length;
      return result;*/
    }

    public get hideBack(): boolean {
    return this.page < 2;
  }

  public get hideForward(): boolean {
    console.log(this.totalRegistrations, this.rowsPerPage);
    const maxPage = this.totalRegistrations / this.rowsPerPage;
    return (this.page >= maxPage);
  }

    updateResults(rowsPerPage: number) {
    // Reset page number
    this.page = 1;
    this.rowsPerPage = rowsPerPage;
    this.load()
    }
    paginateBack() {
      this.page = this.page - 1;
      this.load()
    }
    public paginateForward() {
      this.page = this.page + 1;
      this.load()
    }
   
  }
  